
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import optouts from "@/core/data/optout";
import { IOptOut } from "@/core/data/optout";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ErrorMessage,
    Field,
    Form,
    VPagination,
    // ExportCustomerModal,
    // AddCustomerModal,
  },
  setup() {
    const store = useStore();
    const fileData = ref<any>("");
    const checkedOptOut = ref([]);
    const showTable = ref(false);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "Mobile Number",
        key: "mobileno",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IOptOut>>([]);
    const initCustomers = ref<Array<IOptOut>>([]);
    const onSubmitMultipleMove = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const limit = ref(10);
    const total_pages = ref(1);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Opt Management", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      // console.log('tableData',tableData.value);
      getItem(1);
    });

    const getItem = (page) => {
      var request = {
        url: `/opt?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const changefile = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target) {
          const text = e.target.result;
          const data = csvToArray(text);
          fileData.value = data;
          // console.log('text',data);
        }
      };
      reader.readAsText(file);
    };
    const csvToArray = (str, delimiter = ",") => {
      var headers = str.slice(0, str.indexOf("\n")).split(delimiter);
      headers = headers.map(function (el) {
        return el.trim();
      });
      var arrayOfnumber: any = [];
      var rows = str.slice(str.indexOf("\n") + 1).split("\n");
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        arrayOfnumber.push({ mobileno: values[0] });
        //console.log(values[0]);
        // const el =  headers.reduce(function (object, header, index) {
        //     if(values[index] != undefined){
        //       // console.log('sdfsd',object[header] == 'first_name' )
        //       if(values[index] == ""){
        //      return arrayOfnumber;
        //       }else{

        //         object[header] = values[index].trim();
        //         arrayOfnumber.push({ mobileno: object[header] });

        //       }
        //     }else{
        //       return arrayOfnumber;
        //     }

        //     return arrayOfnumber;
        // }, {});

        //return el;
      });
      return arrayOfnumber;
      // return the array
      // console.log('sdsd',arr);
      //return arr;
    };

    const deleteFewOptOut = () => {
      checkedOptOut.value.forEach((item) => {
        deleteOptOut(item);
      });
      checkedOptOut.value.length = 0;
    };

    const deleteOptOut = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          var request = {
            url: `opt/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            showTable.value = false;
            Swal.fire("Deleted!", "Opt has been deleted.", "success");
            getItem(1);
          });
        }
      });
    };

    const onSubmitRegister = (values, { resetForm }) => {
      onSubmitMultipleMove.value!.disabled = true;
      onSubmitMultipleMove.value?.setAttribute("data-kt-indicator", "on");
      console.log("movefolder", values);
      //  console.log('dffgf',numbersGet.value);
      if (values.upload_file == undefined) {
        Swal.fire({
          text: "Please select CSV!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
      } else if (values.upload_file[0].type != "text/csv") {
        Swal.fire({
          text: "Please select only CSV!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
      } else {
        loading.value = true;
        var request = {
          url: "opt-import",
          data: {
            opt: fileData.value,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              Swal.fire({
                text: "opt import successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-success",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              });
              // Swal.fire("Success", "", "success");
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      onSubmitMultipleMove.value?.removeAttribute("data-kt-indicator");
      onSubmitMultipleMove.value!.disabled = false;
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IOptOut> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getItem(value);
    };

    const exportData = () => {
      var request = {
        url: `/opt/report-export`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        //console.log(data);
        downloadFileForContact(data.data, "OptCsv");
      });
    };

    const downloadFileForContact = (data, filename = "data") => {
      let csvData = ConvertToCSV(data, ["Mobile Number"]);
      // console.log(csvData)
      let blob = new Blob(["\ufeff" + csvData], {
        type: "text/csv;charset=utf-8;",
      });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser =
        navigator.userAgent.indexOf("Safari") != -1 &&
        navigator.userAgent.indexOf("Chrome") == -1;
      if (isSafariBrowser) {
        //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    };

    const ConvertToCSV = (objArray, headerList) => {
      let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      let row = "";

      for (let index in headerList) {
        row += headerList[index] + ",";
      }
      row = row.slice(0, -1);
      str += row + "\r\n";
      for (let i = 0; i < array.length; i++) {
        let line: any = "";
        for (let j = 0; j < headerList.length; j++) {
          var data;
          switch (headerList[j]) {
            case "Mobile Number":
              data = array[i].mobileno ? array[i].mobileno : "0";
              break;
            default:
              data = "0";
          }
          data = data
            .toString()
            .replace(/,/g, " ")
            .replace(/\r\n/g, " ")
            .replace(/\n/g, " ");

          if (line == "") {
            line = data;
          } else {
            line += "," + data;
          }
        }
        str += line + "\r\n";
      }
      return str;
    };
    const page = ref(1);
    return {
      tableData,
      tableHeader,
      deleteFewOptOut,
      search,
      searchItems,
      checkedOptOut,
      deleteOptOut,
      showTable,
      getItem,
      dateTime,
      fileData,
      changefile,
      csvToArray,
      onSubmitMultipleMove,
      loading,
      onSubmitRegister,
      updateHandler,
      limit,
      total_pages,
      exportData,
      page
    };
  },
});
